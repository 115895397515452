.main {
    >div {
        padding: 30px 16px;
        margin: -30px -16px;
        overflow: hidden;
    }
}

@media (min-width: 48rem) {
    .main {
        >div {
            width: calc(100% + 40px);
            padding: 30px 20px;
            margin: -30px 0px -30px -20px;
        }
    }
}