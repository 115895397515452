@import "@style/GConst.scss";

.main {
    height: 44px;
    padding: 12px;
    position: relative;
    z-index: 2;

    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    background: $MAIN_COLOR;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    height: 44px;
    padding: 0px 30px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    width: auto;
    color: rgb(255, 255, 255);

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > svg {
        margin-right: 8px;
    }

    &:hover {
        background-color: rgb(94, 204, 83);
    }

    &:focus {
        outline: none !important;
    }
}

@media (min-width: 48rem) {
    .main {
        padding: 0px 12px;
        width: 170px;
    }
}
