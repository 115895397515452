.main {
    width: 100%;

    @media (min-width: 48rem) {
        display: flex;

        >.right {
            width: 21%;
            margin-bottom: 0px;
            padding-right: 40px;
        }

        >.left {
            width: 79%;

        }

    }

    @media (min-width: 62rem) {}

    @media (min-width: 80rem) {}
}