.main {
    >.tabs {
        display: flex;
        overflow-x: auto;
        background-color: rgb(247, 247, 247);
        padding: 4px;
        border-radius: 12px;

        >.ftZPut {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            cursor: pointer;
            background: rgb(80, 185, 70);
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            border-radius: 8px;
            border: none;
            height: 44px;
            padding: 0px 30px;
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
            width: auto;
            color: rgb(255, 255, 255);
        }

        >.button {
            flex: 1 1 0%;
            width: 100%;
            background-color: rgb(247, 247, 247);
            color: rgb(120, 124, 135);
            border-radius: 12px;
            font-size: 14px;
            line-height: 20px;
            height: auto;
            padding: 8px 12px;
            white-space: nowrap;
            margin: 0px 4px;

            &:hover {
                background-color: rgb(255, 255, 255);
            }
        }

        >.active {
            flex: 1 1 0%;
            width: 100%;
            border-radius: 12px;
            font-size: 14px;
            line-height: 20px;
            height: auto;
            padding: 8px 12px;
            white-space: nowrap;
            margin: 0px 4px;
            background-color: rgb(255, 255, 255);
            color: rgb(22, 22, 22);
        }
    }

    >.tabsContent {
        margin-top: 20px;
        overflow: hidden;
        margin-bottom: 20px;

        >div {
            display: none;
        }

        >.desc {
            img {
                width: 100%;
            }
        }

        >.video {
            text-align: center;

            >div {
                display: flex;
                justify-content: center;

                >video {
                    object-fit: cover;
                    height: 400px;
                    width: 100%;
                    cursor: grab;
                    background: #f2f3f5;
                    border-radius: 4px;
                }
            }
        }

        >.active {
            display: block;
        }
    }

    @media (min-width: 48rem) {
        >.tabsContent {
            margin-top: 40px;
            margin-bottom: 40px;

            >.video {
                >div {
                    >video {
                        object-fit: cover;
                        height: 400px;
                        width: 400px;
                    }
                }
            }
        }
    }
}