@import "@style/GConst.scss";

.card {
    margin-bottom: 8px;
    padding-top: 16px;

    >div {
        >div {
            >div {
                border-radius: 8px;
                box-shadow: rgb(0 0 0 / 5%) 0px 4px 18px;
                padding: 16px;
                background: rgb(255, 255, 255);

                >.orderNumber {
                    display: flex;
                    flex-direction: column;
                    transition: opacity 0.3s ease 0s;
                    opacity: 1;
                    cursor: pointer;

                    >.header {
                        display: flex;
                        -webkit-box-align: center;
                        align-items: center;

                        >:first-child {
                            margin-right: 16px;
                            line-height: 0;

                            >div {
                                display: flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                                width: 44px;
                                height: 44px;
                                flex-shrink: 0;
                                border-radius: 51px;
                                background-color: rgb(255, 226, 226);
                            }
                        }

                        >:last-child {
                            >div {
                                >h3 {
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 19px;
                                    margin: 0px;
                                }

                                >div {
                                    margin-top: 6px;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: rgb(182, 186, 194);
                                }
                            }
                        }
                    }

                    >.transaction {
                        display: flex;
                        -webkit-box-pack: justify;
                        justify-content: space-between;
                        margin-top: 16px;
                    }

                    >.icon {
                        position: absolute;
                        right: 10px;
                        cursor: pointer;
                        opacity: 1;
                        flex-shrink: 0;
                        transition: transform 0.3s ease 0s;
                        margin-left: 24px;
                    }

                    &:hover {
                        text-decoration: none;
                        opacity: 0.7;
                    }
                }

                >.moreProduct {
                    position: relative;

                    >div {
                        min-height: 70px;

                        >:first-child {
                            >.productsTop {
                                display: none;

                                >.productsName {
                                    padding-left: 60px;
                                }

                                >.productsQuantity {
                                    flex-basis: 64px;
                                }

                                >.productsPrice {
                                    flex-basis: 98px;
                                }

                                >.productsMark {
                                    margin-left: auto;
                                }
                            }

                            >.productWrapper {
                                margin-bottom: 20px;

                                >div {
                                    display: flex;
                                    flex-direction: column;

                                    >:first-child {
                                        display: flex;
                                        -webkit-box-align: center;
                                        align-items: center;

                                        >a {
                                            line-height: 0;
                                            position: relative;

                                            >img {
                                                width: 46px;
                                                height: 44px;
                                                margin-right: 16px;
                                                object-fit: contain;
                                            }
                                        }

                                        >div {
                                            >.title {
                                                font-weight: 500;
                                                font-size: 14px;
                                                line-height: 16px;
                                                color: rgb(22, 22, 22);
                                                width: 100%;
                                                margin-bottom: 6px;
                                                transition: color 0.2s ease 0s;

                                                &:hover {
                                                    color: $MAIN_COLOR;
                                                }
                                            }

                                            >.config {
                                                >p {
                                                    >span {
                                                        font-size: 12px;
                                                        line-height: 14px;
                                                        color: #b6bac2;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    >:last-child {
                                        display: flex;
                                        -webkit-box-align: center;
                                        align-items: center;
                                        margin-top: 14px;

                                        >.product__quantity {
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 16px;
                                            color: rgb(22, 22, 22);
                                            margin-right: 28px;
                                        }

                                        >.product__price {
                                            margin-right: auto;

                                            >div {
                                                display: flex;
                                                flex-direction: row;
                                                -webkit-box-pack: start;
                                                justify-content: flex-start;
                                                -webkit-box-align: center;
                                                align-items: center;

                                                >div {
                                                    font-size: 14px;
                                                    line-height: 17px;
                                                    color: rgb(22, 22, 22);
                                                    font-weight: 700;
                                                }
                                            }
                                        }

                                        >.product__rating {
                                            width: 75px;

                                            >div {
                                                >img {
                                                    height: 16px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        >:last-child {
                            >:first-child {
                                display: flex;
                                flex-direction: column-reverse;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                padding-top: 24px;

                                >:first-child {
                                    margin-top: 24px;

                                    >div {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;

                                        >div {
                                            width: 100%;

                                            >div {
                                                display: flex;
                                                -webkit-box-align: baseline;
                                                align-items: baseline;
                                                -webkit-box-pack: justify;
                                                justify-content: space-between;
                                                margin-bottom: 16px;
                                                color: rgb(22, 22, 22);

                                                >.title {
                                                    font-weight: 600;
                                                    font-size: 12px;
                                                    line-height: 14px;
                                                }

                                                >.line {
                                                    flex: 1 1 0%;
                                                    opacity: 0.5;
                                                    margin: 0px 7px;
                                                    border-bottom: 1px dashed rgb(120, 124, 135);
                                                }

                                                >.price {
                                                    color: rgb(33, 33, 33);
                                                    font-weight: 600;
                                                    font-size: 12px;
                                                    line-height: 14px;
                                                }
                                            }

                                            >:last-child {
                                                margin-bottom: 0px;
                                            }
                                        }
                                    }
                                }

                                >:last-child {
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 16px;
                                    margin-bottom: 16px;

                                    >div {
                                        >div {
                                            padding: 16px 14px;
                                            background-color: rgb(247, 247, 247);
                                            border-radius: 12px;
                                            width: 100%;

                                            >div {
                                                display: flex;
                                                flex-direction: column;
                                                align-items: flex-start;

                                                >div {
                                                    display: flex;
                                                    flex-direction: column;
                                                    align-items: flex-start;

                                                    >:first-child {
                                                        display: flex;
                                                        -webkit-box-align: center;
                                                        align-items: center;
                                                        margin-bottom: 7px;
                                                        margin-top: -3px;

                                                        >svg {
                                                            color: $MAIN_COLOR;
                                                        }

                                                        >span {
                                                            font-weight: 600;
                                                            font-size: 14px;
                                                            line-height: 17px;
                                                            color: $MAIN_COLOR;
                                                            margin-left: 8px;
                                                            margin-right: 10px;
                                                        }
                                                    }

                                                    >:last-child {
                                                        margin: 0px 0px 3px;
                                                        font-size: 12px;
                                                        line-height: 16px;
                                                        font-weight: 600;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 48rem) {
        >div {
            >div {
                >div {

                    >.orderNumber {
                        flex-direction: row;
                        -webkit-box-align: center;
                        align-items: center;

                        >.transaction {
                            display: block;
                            margin-top: 0px;
                            margin-left: auto;
                        }

                        >.icon {
                            position: static;
                            right: 0px;
                        }
                    }

                    >.moreProduct {
                        >div {
                            >:first-child {
                                >.productsTop {
                                    display: flex;
                                    -webkit-box-align: center;
                                    align-items: center;
                                    -webkit-box-pack: justify;
                                    justify-content: space-between;
                                    color: rgb(182, 186, 194);
                                    margin-bottom: 20px;
                                    margin-top: 24px;
                                    font-size: 12px;
                                    line-height: 14px;

                                    >.productsName {
                                        width: 53%;
                                    }

                                    >.productsPrice {
                                        margin-left: auto;
                                    }
                                }

                                >.productWrapper {
                                    padding: 14px;
                                    margin-right: -8px;
                                    margin-left: -8px;
                                    margin-bottom: 10px;

                                    >div {
                                        flex-flow: row nowrap;

                                        >:first-child {
                                            width: 56%;

                                            >div {
                                                max-width: 268px;
                                                margin-right: 56px;
                                            }
                                        }

                                        >:last-child {
                                            width: 50%;
                                            margin-top: 0px;
                                            -webkit-box-pack: justify;
                                            justify-content: space-between;

                                            >.product__quantity {
                                                flex-basis: 68px;
                                                margin-right: 0px;
                                            }
                                        }
                                    }
                                }
                            }

                            >:last-child {
                                >:first-child {
                                    padding-bottom: 16px;

                                    >:first-child {
                                        >div {
                                            >div {
                                                width: 75%;
                                                flex-shrink: 0;
                                                margin-right: 20px;
                                            }
                                        }
                                    }

                                    >:last-child {
                                        margin: 16px 0px;

                                        >div {
                                            >div {
                                                >div {
                                                    flex-direction: row;
                                                    -webkit-box-align: center;
                                                    align-items: center;

                                                    >div {
                                                        flex-direction: row;

                                                        >:first-child {
                                                            margin-bottom: 0px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 62rem) {
        >div {
            >div {
                >div {
                    padding: 16px 24px;

                    >.moreProduct {
                        >div {
                            >:first-child {
                                >.productsTop {
                                    >.productsName {
                                        width: 50%;
                                    }
                                }

                                >.productWrapper {
                                    margin-right: -14px;
                                    margin-left: -14px;

                                    >div {
                                        >:first-child {
                                            width: 50%;

                                            >div {
                                                margin-right: 20px;
                                            }
                                        }

                                        >:last-child {
                                            >.product__price {
                                                margin-right: 0px;
                                                flex-basis: 95px;
                                            }
                                        }
                                    }
                                }
                            }

                            >:last-child {
                                >:first-child {
                                    padding: 44px 0px 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 80rem) {
        >div {
            >div {
                >div {
                    >.moreProduct {
                        >div {
                            >:first-child {
                                >.productsTop {
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 16px;
                                }

                                >.productWrapper {
                                    >div {
                                        >:first-child {
                                            >div {
                                                max-width: 375px;
                                                margin-right: 0px;
                                            }
                                        }

                                        >:last-child {
                                            >.product__price {
                                                flex-basis: 110px;

                                                >div>div {
                                                    margin-right: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            >:last-child {
                                >:first-child {
                                    flex-direction: row;

                                    >:first-child {
                                        width: 42%;
                                        margin-top: 0px;

                                        >div {
                                            >div {
                                                width: 364px;
                                            }
                                        }
                                    }

                                    >:last-child {
                                        width: 55%;
                                        margin: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}