.main {
    width: 100%;
    text-align: center;
}

.ellipsis {
    width: 80px;
    height: 80px;
    display: inline-block;
    position: relative;
}

.ellipsis>div {
    top: 33px;
    width: 13px;
    height: 13px;

    position: absolute;
    border-radius: 50%;
    background-color: rgb(80, 185, 70);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ellipsis>div:nth-child(1) {
    left: 8px;
    animation: 0.6s ease 0s infinite normal none running ellipsis1;
}

.ellipsis>div:nth-child(2) {
    left: 8px;
    animation: 0.6s ease 0s infinite normal none running ellipsis2;
}

.ellipsis>div:nth-child(3) {
    left: 32px;
    animation: 0.6s ease 0s infinite normal none running ellipsis2;
}

.ellipsis>div:nth-child(4) {
    left: 56px;
    animation: 0.6s ease 0s infinite normal none running ellipsis3;
}

@keyframes ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes ellipsis2 {
    0% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(24px, 0px);
    }
}

@keyframes ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}