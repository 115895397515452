.main {
    >h1 {
        font-size: 22px;
        line-height: 28px;
        margin-right: 24px;
        margin-bottom: 16px;
        font-weight: 700;
    }
}

@media (min-width: 48rem) {
    .main {
        >h1 {
            font-size: 28px;
            line-height: 34px;
        }
    }
}

@media (min-width: 62rem) {
    .main {
        >h1 {
            // font-size: 44px;
            // line-height: 48px;
        }
    }
}

@media (min-width: 80rem) {
    .main {
        >h1 {
            // font-size: 32px;
            // line-height: 40px;
        }
    }
}