.main {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    >:first-child {
        >img {
            width: 100%;
        }
    }

    >:last-child {
        margin-top: 24px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex-direction: column;

        >.notify_title {
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            color: rgb(22, 22, 22);
            margin-bottom: 16px;
            text-align: center;
        }

        >.notify_description {
            font-size: 14px;
            line-height: 24px;
            color: rgb(22, 22, 22);
            margin-bottom: 24px;
            max-width: 300px;
            text-align: center;
        }

        >button {
            max-width: 188px;
            width: 100%;
            font-size: 14px;
            height: 44px;
            padding: 0px;
        }
    }

    @media (min-width: 48rem) {
        flex-direction: row;

        >:last-child {
            margin-top: 0px;
            margin-left: 58px;
            align-items: flex-start;

            >.notify_title {
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 24px;
                text-align: left;
            }

            >.notify_description {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 32px;
                text-align: left;
                max-width: 335px;
            }
        }
    }

    @media (min-width: 80rem) {
        >:last-child {
            margin-left: 80px;

            >.notify_title {
                font-size: 28px;
                line-height: 36px;
            }

            >.notify_description {
                font-size: 18px;
                line-height: 30px;
                max-width: 490px;
            }

            >button {
                max-width: 250px;
                font-size: 16px;
                height: 56px;
            }
        }
    }
}