@import "@style/GConst.scss";

.main {
    width: 100%;
    display: flex;

    >aside {
        width: 250px;
        flex: 0 0 auto;
        display: none;
        margin-right: 40px;

        >div {
            position: sticky;
            top: 89px;

            >ul {
                padding: 0px;
                margin: 0px px;
                list-style: none;
                display: flex;
                flex-direction: column;
                font-size: 16px;
                line-height: 19px;

                >li {
                    margin-bottom: 18px;
                    font-size: 16px;

                    > :last-child {
                        margin-bottom: 0px;
                    }

                    >a {
                        color: rgb(22, 22, 22);
                        display: inline-flex;
                        -webkit-box-align: center;
                        align-items: center;
                        margin: 0px;
                        transition: opacity 0.3s ease 0s;
                        opacity: 1;
                        font-weight: 500;
                    }

                    >.active {
                        color: $MAIN_COLOR;
                    }
                }
            }
        }
    }

    >.right {
        flex: 1 1 auto;
    }

    @media (min-width: 48rem) {}

    @media (min-width: 62rem) {
        >aside {
            display: block;
        }
    }

    @media (min-width: 80rem) {
        >aside {
            margin-right: 40px;
        }
    }
}