.main {
    width: 100%;
    padding-bottom: 64px;

    .content__header__counter {
        button {
            width: 100%;
        }

        >div {
            >div {
                margin-bottom: 10px;
            }
        }
    }

    >.large__header {
        display: none;
        position: fixed;
        inset: 0px;
        overflow: auto;
        outline: 0px;
        z-index: 10000;

        >:first-child {
            -webkit-box-align: center;
            align-items: center;
            background-color: rgb(255, 255, 255);
            box-shadow: rgb(0 0 0 / 5%) 0px 4px 18px;
            display: flex;
            flex-shrink: 0;
            height: 8%;
            padding: 0px 20px;

            >div {
                display: flex;
                flex: 1 1 33%;
            }

            >div:nth-child(1) {
                >button {
                    background-color: transparent;
                    border: 0px;
                    color: rgb(80, 185, 70);
                    cursor: pointer;
                    flex-shrink: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 18px;
                    outline: 0px;
                    display: flex;
                    align-items: center;

                    >span {
                        margin-left: 8px;
                    }
                }
            }

            >div:nth-child(2) {
                -webkit-box-pack: center;
                justify-content: center;

                >span {
                    color: rgb(22, 22, 22);
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 26px;
                    text-align: center;
                }
            }

            >div:nth-child(3) {
                -webkit-box-pack: end;
                justify-content: flex-end;

                >button {
                    background-color: transparent;
                    border: 0px;
                    color: rgb(182, 186, 194);
                    cursor: pointer;
                    line-height: 19px;
                    font-size: 16px;
                    font-weight: 500;
                    outline: 0px;
                    padding: 0px;
                }
            }
        }

        >:last-child {
            background-color: #f7f7f7;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 8px;
            height: 92%;
            overflow: scroll;
        }
    }

    >.large {
        display: block;
    }

    > :last-child {
        >.content__header {
            display: flex;
            flex-direction: column;

            > :last-child {
                margin-bottom: 32px;
            }

            >.content__header__title {
                -webkit-box-align: center;
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 18px;

                >h1 {
                    -webkit-box-flex: 1;
                    flex-grow: 1;
                    font-size: 22px;
                    line-height: 28px;
                    margin: 18px 0px 0px;
                    font-weight: 700;
                }

                >div {
                    display: none;
                }

                >.largeButton {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    cursor: pointer;
                    background: rgb(80, 185, 70);
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    border-radius: 8px;
                    border: none;
                    height: 44px;
                    padding: 0px 30px;
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                    width: auto;
                    color: rgb(255, 255, 255);

                    margin-top: 18px;
                    -webkit-tap-highlight-color: transparent;
                    background-color: rgba(80, 185, 70, 0.1);
                    color: rgb(80, 185, 70);
                    height: 40px;
                    padding: 0px 18px;

                    >span {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-left: 5px;
                    }
                }
            }

            >.content__header__counter {
                >div {
                    -webkit-box-align: center;
                    align-items: center;
                    display: flex;

                    > :first-child {
                        color: rgb(120, 124, 135);
                        font-size: 14px;
                        line-height: 16px;
                    }

                    > :last-child {
                        display: none;
                        margin-left: auto;
                    }

                    .skeleton__total {
                        height: 16px;
                        width: 80px;
                    }
                }
            }
        }

        >.button {
            text-align: center;

            >.disable {
                opacity: 0.5;
            }

            >button {
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                cursor: pointer;
                background: rgb(238, 248, 237);
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                border-radius: 8px;
                border: none;
                height: 44px;
                padding: 0px 30px;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                width: auto;
                color: rgb(80, 185, 70);

                &:hover {
                    color: rgb(255, 255, 255);
                    background-color: rgb(80, 185, 70);
                }
            }
        }
    }
}

@media (min-width: 48rem) {
    .main {
        >.filter {
            >.large__header {
                margin: 0px 40px;
            }
        }
    }
}

@media (min-width: 62rem) {
    .main {
        > :last-child {
            >.content__header {
                >.content__header__title {
                    align-items: flex-end;
                    display: flex;
                    margin-bottom: 32px;

                    >h1 {
                        font-size: 28px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}

@media (min-width: 80rem) {
    .main {
        display: flex;

        >.filter {
            >div {
                >div {
                    display: block;
                }
            }
        }

        > :first-child {
            display: block;
            flex-shrink: 0;
            padding-right: 40px;
            width: 21%;
            margin-top: -6px;
        }

        > :last-child {
            width: 79%;

            >.content__header {
                >.content__header__title {
                    >h1 {
                        font-size: 36px;
                        line-height: 48px;
                    }

                    >div {
                        display: block;
                    }
                }

                >.content__header__counter {
                    >div {
                        > :last-child {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}