@import "@style/GConst.scss";

.main {
    > :first-child {
        margin-bottom: 16px;
    }

    > .title {
        > h1 {
            font-size: 20px;
            line-height: 26px;
            font-weight: bold;
            word-break: break-word;
            margin: 0px 0px 16px;
        }

        .skeleton {
            width: 120px;
            margin-bottom: 10px;
        }

        > :last-child {
            margin-bottom: 24px;

            > div {
                > div {
                    display: flex;
                    flex-wrap: wrap;

                    > :first-child {
                        margin-right: 24px;

                        > div {
                            > img {
                                height: 14px;
                            }
                        }

                        .skeleton {
                            width: 80px;
                            height: 16px;
                        }
                    }

                    > :last-child {
                        > div {
                            > svg {
                                color: #c8c8c8;
                            }
                        }

                        .skeleton {
                            width: 80px;
                            height: 20px;
                        }
                    }

                    > div {
                        display: flex;
                        align-items: center;

                        > :first-child {
                            display: flex;
                            align-items: center;
                        }

                        > :last-child {
                            margin-left: 9px;
                            color: rgb(182, 186, 194);
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }

    > .product_price_wrapper {
        > div {
            border-top: 1px solid rgb(242, 243, 245);
            padding-top: 16px;

            > .sku {
                // border-bottom: 1px solid rgb(242, 243, 245);
                margin-top: 16px;

                > div {
                    > div {
                        display: flex;
                        align-items: center;

                        > h2 {
                            white-space: nowrap;
                            font-size: 22px;
                            line-height: 28px;
                            font-weight: 700;
                            margin: 16px 0px;
                            text-transform: capitalize;
                        }

                        > div {
                            font-size: 14px;
                            color: #b6bac2;
                            font-weight: 500;
                            margin-left: 10px;
                        }
                    }

                    > ul {
                        > li {
                            display: inline-block;
                            position: relative;
                            margin: 0 4px 4px 0;
                            vertical-align: middle;
                            border: 2px solid #e6e9e6;
                            border-radius: 8px;
                            padding: 0 9px;
                            max-height: 50px;
                            overflow: hidden;
                            line-height: 50px;
                            cursor: pointer;

                            -webkit-tap-highlight-color: transparent;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            &:focus {
                                outline: none !important;
                            }
                        }

                        > .image {
                            width: 50px;
                            height: 50px;
                            padding: 0;
                            line-height: 38px;
                            background-position: center center;
                            background-repeat: no-repeat;
                            outline: 0;

                            > span {
                                display: block;
                                text-indent: -9999em;
                            }
                        }

                        > .active {
                            border: 2px solid $MAIN_COLOR;
                        }
                    }
                }
            }

            > .price_card {
                display: flex;
                -webkit-box-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                align-items: center;
                margin-top: 12px;
                border-bottom: 1px solid rgb(242, 243, 245);
                padding-bottom: 16px;

                > div {
                    color: rgb(182, 186, 194);
                }

                > :first-child {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                }

                > :last-child {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            > .product_price_wrapper__price {
                display: flex;
                -webkit-box-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                align-items: center;

                .skeleton {
                    width: 80px;
                    height: 20px;
                }

                > :first-child {
                    > .price_new {
                        font-weight: 600;
                        margin-right: 8px;
                        font-size: 32px;
                        line-height: 24px;
                        transition: font-size 0.2s ease 0s;
                        margin-bottom: 4px;
                    }
                }

                > .old {
                    display: flex;
                    flex-direction: row;
                    -webkit-box-pack: start;
                    justify-content: flex-start;
                    -webkit-box-align: baseline;
                    align-items: baseline;

                    flex-wrap: wrap;
                    padding-right: 6px;

                    > .price_new {
                        color: rgb(219, 54, 54);
                    }

                    > :last-child {
                        display: flex;
                        -webkit-box-align: center;
                        align-items: center;

                        > div {
                            color: rgb(120, 124, 135);
                            font-size: 20px;
                            line-height: 28px;
                            transition: font-size 0.2s ease;
                            text-decoration: line-through;
                        }
                    }
                }

                > :last-child {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;

                    > .heart {
                        border: 0px;
                        cursor: pointer;
                        line-height: 0;
                        outline: 0px;
                        transition: opacity 0.2s ease 0s;
                        display: flex;
                        padding: 0px;
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        height: 44px;
                        width: 44px;
                        background-color: rgb(242, 243, 245);
                        border-radius: 8px;
                        margin-left: 8px;

                        > svg {
                            color: rgb(182, 186, 194);
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 48rem) {
    .main {
        > .title {
            .skeleton {
                width: 400px;
                height: 20px;
            }

            > h1 {
                font-size: 24px;
                line-height: 36px;
                word-break: normal;
                margin-bottom: 24px;
            }

            > :last-child {
                margin-bottom: 32px;

                > div {
                    > div {
                        > :first-child {
                            > div {
                                > img {
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        > .product_price_wrapper {
            > div {
                border: none;
                box-shadow: rgb(90 112 114 / 8%) 0px 4px 18px;
                border-radius: 8px;
                padding: 35px 30px;

                > .sku {
                    border-top: 1px solid rgb(242, 243, 245);

                    > div {
                        > div {
                            > .imgUrlBlock,
                            > .span {
                                width: 13%;
                            }
                        }

                        > div {
                            > h2 {
                                font-weight: 600;
                                font-size: 18px;
                            }
                        }
                    }
                }

                > .price_card {
                    margin-top: 14px;
                    border-bottom: 0px;
                    padding-bottom: 0px;

                    .skeleton {
                        height: 20px;
                        width: 80px;
                    }
                }
            }
        }
    }
}

@media (min-width: 62rem) {
    .main {
        > .title {
            > :last-child {
                margin-bottom: 24px;
            }
        }
    }
}

@media (min-width: 80rem) {
    .main {
        > .title {
            > h1 {
                font-size: 28px;
                line-height: 34px;
            }
        }

        > .product_price_wrapper {
            max-width: 473px;
        }
    }
}
