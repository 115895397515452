.main {
    > :first-child {
        position: fixed;
        inset: 0px;
        z-index: 10000;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
    }

    > :last-child {
        display: block;
        position: fixed;
        inset: 0px;
        overflow: auto;
        outline: 0px;
        z-index: 10000;

        >div {
            inset: 0px;
            color: rgb(22, 22, 22);
            font-size: 14px;
            height: 100%;
            pointer-events: none;
            position: absolute;
            width: 570px;
            max-width: calc(100vw - 32px);
            padding: 18px 0px 24px;
            margin: 0px auto;

            >div {
                position: relative;
                background-color: rgb(255, 255, 255);
                background-clip: padding-box;
                border: 0px;
                pointer-events: auto;
                padding: 64px 44px 44px;
                border-radius: 15px;
                box-shadow: rgb(0 0 0 / 5%) 0px 4px 18px;

                >button {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    cursor: pointer;
                    color: rgb(120, 124, 135);
                    background: transparent;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    border-radius: 8px;
                    border: none;
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                    width: auto;

                    position: absolute;
                    top: 20px;
                    right: 15px;
                    z-index: 4;
                    padding: 0px;
                    height: auto;

                    >span {
                        display: flex;
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media (min-width: 62rem) {
        > :last-child {
            >div {
                padding-top: 64px;

                >div {
                    >button {
                        top: 30px;
                        right: 30px;
                    }

                    >div {
                        >div {
                            display: flex;
                            -webkit-box-align: center;
                            align-items: center;
                            flex-direction: column;
                            -webkit-box-pack: center;
                            justify-content: center;
                            position: absolute;
                            inset: 0px;
                            padding: 0px 20px;

                            >img {
                                width: 126px;
                                margin-right: 44px;
                            }

                            >div {
                                >.title {
                                    margin-bottom: 16px;
                                    font-weight: 700;
                                    font-size: 22px;
                                    line-height: 28px;
                                    text-align: center;
                                    max-width: 240px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 48rem) {
        > :last-child {
            >div {
                >div {
                    >div {
                        >div {
                            flex-direction: row;
                            position: static;
                            padding: 0px;
                            -webkit-box-pack: start;
                            justify-content: flex-start;
                            align-items: flex-start;

                            >div {
                                >.title {
                                    text-align: left;
                                    margin-bottom: 12px;
                                    max-width: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 80rem) {
        > :last-child {
            >div {
                >div {
                    >div {
                        >div {
                            >div {
                                >.title {
                                    font-size: 28px;
                                    line-height: 36px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}