.main {
    background-color: rgb(247, 247, 247);
    padding: 44px 0px;
    > div {
        display: block;
        padding: 0px 1rem;
        max-width: 100%;
        > :first-child {
            > div {
                > :first-child {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.045em;
                    text-transform: uppercase;
                    margin: 0px 0px 16px;
                    color: rgb(182, 186, 194);
                }
                > :last-child {
                    margin-bottom: 24px;

                    > ul {
                        padding: 0px;
                        margin: 0px;
                        list-style: none;
                        display: flex;
                        flex-direction: column;
                        font-size: 16px;
                        line-height: 19px;
                        > li {
                            margin-bottom: 22px;
                            > a {
                                color: rgb(22, 22, 22);
                                display: inline-flex;
                                -webkit-box-align: center;
                                align-items: center;
                                margin: 0px;
                                transition: opacity 0.3s ease 0s;
                                opacity: 1;
                                text-decoration: none;
                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }
            }
        }
        > :last-child {
            width: 100%;
            margin-top: 44px;

            > :first-child {
                height: 1px;
                background-color: rgb(229, 230, 232);
            }

            > :last-child {
                color: rgb(120, 124, 135);
                font-size: 14px;
                letter-spacing: 0.5px;
                line-height: 16px;
                margin-top: 24px;
            }
        }
    }
}

@media (min-width: 48rem) {
    .main {
        > div {
            padding: 0px 2rem;
            > :first-child {
                display: flex;
                flex-wrap: wrap;
                > div {
                    width: 350px;
                    border-bottom: 0px;
                    > :last-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
@media (min-width: 62rem) {
    .main {
        > div {
            > :first-child {
                > div {
                    width: 400px;
                }
            }
        }
    }
}
@media (min-width: 80rem) {
    .main {
        padding: 84px 0px 44px;
        > div {
            > :first-child {
                flex-wrap: nowrap;
                > div {
                    width: 315px;
                }
            }

            > :last-child {
                margin-top: 64px;
            }
        }
    }
}
