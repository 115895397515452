.main {
    > div {
        > .seller {
            display: flex;
            flex-direction: column;
            align-items: center;
            > :first-child {
                display: flex;
                flex-direction: column;
                align-items: center;
                > p {
                    font-size: 12px;
                    color: #111;
                    margin-top: 8px;
                }
                > div {
                    padding: 0;
                    border-radius: 4px;
                    cursor: pointer;
                    height: 80px;
                    width: 80px;
                    overflow: hidden;
                    > div {
                        padding-top: 100%;
                        position: relative;
                        > div {
                            border-radius: inherit;
                            bottom: 0;
                            left: 0;
                            position: absolute;
                            right: 0;
                            top: 0;
                            > div {
                                background-color: #dddddd;
                                height: 100%;
                                position: relative;
                                width: 100%;
                                > img {
                                    background-size: cover;
                                    -o-object-fit: cover;
                                    object-fit: cover;
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            > :last-child {
                margin-top: 8px;
                display: flex;
                > div {
                    text-align: center;
                    padding: 5px 7px;
                    > :first-child {
                        font-size: 14px;
                    }
                    > :last-child {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (min-width: 48rem) {
    .main {
        > .title {
            > h2 {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

@media (min-width: 80rem) {
    .main {
        position: sticky;
        top: 89px;
        > .title {
            > h2 {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 24px;
            }
        }
    }
}
