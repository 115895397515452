@import "@style/GConst.scss";

.main {
    >.button {
        >:first-child>button {
            width: 100%;
        }

        >:last-child {
            margin-bottom: 32px;
            margin-top: 32px;
            display: flex;
            justify-content: center;

            >button {
                font-weight: 600;
                background: none;
                border: none;
                color: $MAIN_COLOR;
                font-size: 16px;
            }
        }
    }
}