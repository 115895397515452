@import "@style/GConst.scss";

.main {
    position: relative;
    background: #fff;
    z-index: 100;

    >.modal {
        >:last-child {
            >div>div>:last-child>div {
                justify-content: center;
            }
        }
    }

    >.hidden__CHeader {
        max-height: 64px;

        >.HeaderMain {
            transform: translate3d(0px, -102%, 0px);
            padding-top: 0 !important;
        }

        >.isMobileSearch {
            transform: translate3d(0px, -60%, 0px) !important;
        }
    }

    >.CHeader {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 20;
        background-color: rgb(255, 255, 255);
        transition: max-height 0.15s ease-out 0s;

        >.isSearch {
            position: fixed;
            height: 100vh;
            width: 100%;
            top: 0px;
            left: 0px;
            background: rgba(0, 0, 0, 0.5);
            z-index: 16;
        }

        >.HeaderMain {
            transition: transform 0.15s ease-out 0s;
            background-color: #fff;
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            position: relative;
            padding-top: 10px;
            width: 100%;

            >.container {
                justify-content: space-between;

                >.logo {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    margin-right: auto;

                    >div {
                        -webkit-tap-highlight-color: transparent;
                        -webkit-box-align: center;
                        align-items: center;
                        display: flex;
                        transition: opacity 0.15s ease 0s, width 0.15s ease 0s;
                        width: 36px;

                        >svg {
                            display: none;
                            position: absolute;
                            width: 184px;
                            // width: 144px;
                            height: 40px;
                        }

                        >a {
                            display: flex;

                            >img {
                                transition: transform 0.15s ease 0s, opacity 0.15s ease 0s;
                                width: 34px;
                                height: 34px;
                            }
                        }

                        @media screen and (min-width: 80rem) {
                            // width: 214px;
                            width: 184px;
                        }
                    }

                    @media screen and (min-width: 48rem) {
                        margin-right: 32px;
                    }
                }

                >.search {
                    position: relative;
                    display: flex;
                    margin-right: auto;

                    > :first-child {
                        margin-right: auto;

                        >a {
                            text-decoration: none;
                            -webkit-tap-highlight-color: transparent;
                            -webkit-box-align: center;
                            align-items: center;
                            background-color: $MAIN_COLOR;
                            border-radius: 8px;
                            color: rgb(255, 255, 255);
                            display: inline-flex;
                            height: 40px;
                            padding: 0px 17px 0px 16px;
                            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

                            >p {
                                margin: 0;
                                font-size: 16px;
                                line-height: 16px;
                                font-weight: 500;
                            }

                            &:hover {
                                background-color: rgb(94, 204, 83);
                            }
                        }
                    }
                }

                >.delivery {
                    background-color: rgb(228, 251, 225);
                    color: $MAIN_COLOR;
                    -webkit-box-align: center;
                    align-items: center;
                    border: none;
                    border-radius: 7px;
                    cursor: pointer;
                    display: inline-flex;
                    font-size: 12px;
                    height: 40px;
                    margin-right: auto;
                    min-width: 230px;
                    outline: none;
                    opacity: 1;
                    padding: 0px 19px 0px 12px;
                    transition: width 0.3s ease 0s, opacity 0.2s ease 0s;
                    text-align: left;
                    width: 100%;

                    > :first-child {
                        -webkit-box-align: center;
                        align-items: center;
                        display: flex;
                        -webkit-box-pack: center;
                        justify-content: center;
                        flex-shrink: 0;
                        margin-right: 12px;
                        height: 24px;
                        width: 24px;

                        >svg {
                            font-size: 24px;
                        }
                    }

                    > :last-child {
                        >p {
                            margin: 0px;
                            font-size: 12px;
                            line-height: 13px;
                            font-weight: 600;
                        }
                    }
                }

                >.profile {
                    display: flex;
                    align-items: center;

                    svg {
                        font-size: 19px;
                    }

                    >div {
                        margin-left: 28px;
                        transition: opacity 0.2s ease 0s;

                        >button {
                            display: flex;
                            border: none;
                            background: none;
                            cursor: pointer;

                            >:hover {
                                opacity: 0.7;
                            }

                            >svg {
                                color: $ICON_COLOR;
                            }
                        }

                        &:first-child {
                            margin-left: 0px;
                        }
                    }

                    >.menu {
                        display: flex;

                        >svg {
                            color: $MAIN_COLOR;
                        }
                    }
                }
            }
        }

        >.isFocus {
            z-index: auto !important;
        }

        >.isMobileSearch {
            padding: 12px 0px;
            box-shadow: rgb(0 0 0 / 4%) 0px 14px 18px;
            transition: transform 0.15s ease-out 0s;
            transform: none;
            z-index: 99;
        }

        >.HeaderBottom {
            background-color: rgb(255, 255, 255);
            bottom: 0px;
            display: none;
            padding: 9px 0px 15px;
            position: absolute;
            transition: height 0.2s ease 0s, transform 0.2s ease 0s;
            height: 56px;
            transform: translateY(55px);
            box-shadow: rgb(0 0 0 / 6%) 0px 4px 24px;
            width: 100%;
            z-index: 10;

            >div {
                > :first-child {
                    width: 60%;
                }

                > :last-child {
                    width: 40%;
                    display: flex;
                    justify-content: space-between;
                    margin-left: 40px;

                    >div {
                        >a {
                            -webkit-tap-highlight-color: transparent;
                            -webkit-box-align: center;
                            align-items: center;
                            color: rgb(22, 22, 22);
                            display: flex;
                            white-space: nowrap;
                            text-decoration: none;
                            padding: 0px;
                            font-weight: 500;
                            transition: opacity 0.3s ease 0s;

                            >svg {
                                margin-right: 5px;
                                color: $MAIN_COLOR;
                            }

                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }

        >.HBHide {
            transform: translateY(0px);
        }
    }

    >.HOffset {
        height: 146px;
    }

    @media (min-width: 48rem) {
        >.CHeader {
            >.HeaderMain {
                >.container {
                    >.logo {
                        >.scroll {}

                        >div {
                            // >svg {
                            //     width: 36px;
                            //     height: 36px;
                            // }
                        }
                    }

                    >.delivery {
                        display: flex;
                        min-width: 270px;
                        width: 270px;
                    }

                    >.search {
                        // margin-right: 12px;

                        > :first-child {
                            margin-right: 0px;
                            position: relative;
                            z-index: 41;
                        }
                    }

                    >.profile {
                        svg {
                            font-size: 22px;
                        }
                    }
                }
            }
        }

        >.HOffset {
            height: 120px;
        }
    }

    @media (min-width: 62rem) {
        >.CHeader {
            >.HeaderMain {
                height: 64px;
                padding: 0px;
                z-index: 15;
                box-shadow: rgb(0 0 0 / 5%) 0px 4px 18px;
            }
        }

        >.HOffset {
            height: 50px;
        }
    }

    @media (min-width: 80rem) {
        >.CHeader {
            >.HeaderMain {
                height: 70px;
                box-shadow: inherit;

                >.container {
                    .logo {
                        >div {
                            >svg {
                                display: flex;
                                transform: rotateY(0deg);
                                opacity: 1;
                            }

                            >a>img {
                                backface-visibility: hidden;
                                transform: rotateY(180deg);
                                opacity: 0;
                            }

                            >svg,
                            >a>img {
                                transition: transform 0.15s ease 0s, opacity 0.15s ease 0s;
                            }
                        }

                        >.isFocusImg {
                            width: 36px;

                            >svg {
                                display: flex;
                                transform: rotateY(180deg);
                                opacity: 0;
                            }

                            >span>img {
                                backface-visibility: hidden;
                                transform: rotateY(0deg);
                                opacity: 1;
                            }
                        }
                    }

                    >.search {
                        width: 100%;
                        z-index: 40;

                        > :first-child {
                            z-index: 50;
                        }
                    }

                    >.delivery {
                        margin-left: 20px;
                        margin-right: 40px;
                        min-width: 280px;
                        width: 280px;
                    }

                    >.profile {
                        >.menu {
                            display: none;
                        }
                    }
                }
            }

            >.HeaderBottom {
                display: flex;
            }
        }

        >.HOffset {
            height: 125px;
        }
    }
}