.main {
    margin-top: 32px;

    >:first-child {
        display: flex;
        align-items: center;

        >:first-child {
            display: flex;
        }

        >:last-child {
            display: flex;
        }

        >:last-child {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin-left: 8px;
        }
    }

    >.input {
        display: flex;
        margin-top: 12px;

        >:last-child {
            margin-left: 8px;
        }
    }

    >.button {
        margin-top: 10px;

        >button {
            width: 100%;
        }
    }
}