@import "@style/GConst.scss";

.main {
    >div {
        cursor: pointer;

        >div {
            flex-shrink: inherit;
            white-space: nowrap;
            margin-right: 20px;
        }
    }

    >.swiperPrev {
        left: -3px;
        top: 19px;
        filter: drop-shadow(white 10px 0px 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 51px;
        background-color: white;
        box-shadow: rgb(90 112 114 / 8%) 0px 4px 18px;
        color: $ICON_COLOR;

        &:after {
            font-size: 12px;
        }
    }

    >.swiperNext {
        right: -3px;
        top: 19px;
        filter: drop-shadow(rgb(255, 255, 255) -10px 0px 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 51px;
        background-color: white;
        box-shadow: rgb(90 112 114 / 8%) 0px 4px 18px;
        color: $ICON_COLOR;

        &:after {
            font-size: 12px;
        }
    }
}