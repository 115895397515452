.main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    >a {
        padding: 0;
        border-radius: 4px;
        cursor: pointer;
        height: 80px;
        margin: 8px 8px 0 0;
        width: 80px;
        overflow: hidden;

        >div {
            padding-top: 100%;
            position: relative;

            >div {
                border-radius: inherit;
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                >div {
                    background-color: #dddddd;
                    height: 100%;
                    position: relative;
                    width: 100%;

                    >img {
                        background-size: cover;
                        -o-object-fit: cover;
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                        mix-blend-mode: multiply;
                        background-repeat: no-repeat;
                        display: inline-block;
                    }
                }
            }
        }
    }
}