.main {
    >:first-child {
        >h2 {
            font-size: 24px;
            line-height: 29px;
            margin-right: 24px;
            margin-bottom: 16px;
            font-weight: 700;
        }
    }

    @media (min-width: 48rem) {
        >:first-child {
            >h2 {
                font-size: 32px;
                line-height: 39px;
            }
        }
    }

    @media (min-width: 62rem) {
        >:first-child {
            >h2 {
                font-size: 44px;
                line-height: 48px;
            }
        }
    }

    @media (min-width: 80rem) {
        >:first-child {
            display: flex;
            align-items: center;

            >h2 {
                font-size: 44px;
                line-height: 40px;
            }
        }

    }
}