.main {
    width: 100%;

    >div {
        margin-bottom: 44px;

        > :last-child {
            margin-bottom: 0px;
        }
    }

    >.full_width_section {
        width: 100%;

        > :first-child {
            position: relative;
            box-sizing: border-box;
            display: flex;
            flex: 0 1 auto;
            flex-flow: row wrap;
            margin-bottom: 24px;

            >div {
                flex-basis: 100% !important;
                max-width: 100%;
                display: block;
                box-sizing: border-box;
                flex: 0 0 auto;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }
        }
    }

    >.recommend_bottom {
        > :first-child {
            margin-bottom: 44px;
        }

        >.ratingsAndReviews {
            box-sizing: border-box;
            display: flex;
            flex: 0 1 auto;
            flex-flow: row wrap;
            margin-right: -0.5rem;
            margin-left: -0.5rem;

            >.review {
                box-sizing: border-box;
                flex: 0 0 auto;
                padding-right: 0.5rem;
                padding-left: 0.5rem;

                flex-basis: 100%;
                max-width: 100%;
                display: block;
            }

            >.seller {
                box-sizing: border-box;
                flex: 0 0 auto;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                flex-basis: 100%;
                max-width: 100%;
            }

            >.call {
                box-sizing: border-box;
                flex: 0 0 auto;
                padding-right: 0.5rem;
                padding-left: 0.5rem;

                flex-basis: 100%;
                max-width: 100%;
                display: block;
            }
        }
    }

    >.stiky {
        position: sticky;
        z-index: 10;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-top: 32px;

        background-color: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 6%) 0px -8px 24px;
        padding: 16px 1rem;
        margin: -30px -16px 0px -16px;

        >div {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;

            >div {
                >.price_new {
                    font-weight: 700;
                    margin-right: 8px;
                    font-size: 32px;
                    line-height: 24px;
                    transition: font-size 0.2s ease 0s;
                    margin-bottom: 4px;
                    font-size: 24px;
                    line-height: 24px;
                    margin: 5px 0px 0px;
                }

                >.price_old_wrapper {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;

                    >.price_old {
                        color: rgb(120, 124, 135);
                        transition: font-size 0.2s ease 0s;
                        text-decoration: line-through;

                        font-size: 16px;
                        line-height: 16px;
                    }

                    >.price_additional {
                        margin-left: 8px;

                        >div {
                            display: flex;
                            -webkit-box-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            justify-content: center;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 25px;
                            padding: 0px 6px;
                            height: 18px;
                            background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTYgMzYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTQ3LjQyIDM2aC44OGEzLjUzIDMuNTMgMCAwMDMuMzYtMi41OGw0LjItMTQuMzhjLjE5LS42OC4xOS0xLjQgMC0yLjA4bC00LjItMTQuMzhBMy41MyAzLjUzIDAgMDA0OC4zIDBINy43YTMuNTMgMy41MyAwIDAwLTMuMzYgMi41OEwuMTQgMTYuOTZjLS4xOS42OC0uMTkgMS40IDAgMi4wOGw0LjIgMTQuMzhBMy41MyAzLjUzIDAgMDA3LjcgMzZoMzkuNzJ6IiBmaWxsPSIjRkFENjU2Ii8+Cjwvc3ZnPgo=);
                            color: rgb(22, 22, 22);
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }

            >.old {
                display: flex;
                flex-direction: column-reverse;

                >.price_new {
                    color: rgb(219, 54, 54);
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media (min-width: 48rem) {
    .main {
        >.full_width_section {
            > :first-child {
                margin-bottom: 40px;
            }
        }

        >.recommend_bottom {
            > :first-child {
                margin-bottom: 64px;
            }
        }

        >div {
            margin-bottom: 60px;
        }

        >.stiky {
            padding: 16px 0px;
            background-color: rgb(255, 255, 255);
            box-shadow: rgb(0 0 0 / 6%) 0px -8px 24px;
            padding: 30px 20px;
            margin: -30px 0px -30px -20px;
        }
    }
}

@media (min-width: 62rem) {
    .main {
        >.full_width_section {
            > :first-child {
                >div {
                    flex-basis: 50%;
                    max-width: 50%;
                    display: block;
                }
            }
        }

        >.recommend_bottom {
            > :first-child {
                margin-bottom: 98px;
            }

            >.ratingsAndReviews {
                >.review {
                    flex-basis: 66.6667%;
                    max-width: 66.6667%;
                    display: block;
                }

                >.seller {
                    flex-basis: 33.3333%;
                    max-width: 33.3333%;
                    display: block;
                }
            }
        }
    }
}

@media (min-width: 80rem) {
    .main {
        >.recommend_bottom {
            >.ratingsAndReviews {
                >.review {
                    flex-basis: 50%;
                    max-width: 50%;
                    display: block;
                }

                >.seller,
                >.call {
                    flex-basis: 25%;
                    max-width: 25%;
                    display: block;
                }
            }
        }
    }
}