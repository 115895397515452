.main {
    width: 100%;
    padding-bottom: 64px;
    > .CMenu {
        display: none;

        > div {
            margin-top: 64px;
        }
    }

    > .CContent {
        > div {
            > .catalog__list {
                > .catalog__list__item {
                    > div {
                        > h3 {
                            text-transform: capitalize;
                            margin-right: 24px;
                            margin-bottom: 16px;
                            font-weight: 700;

                            &:target {
                                // scroll-padding-top: 65px;
                                scroll-margin-top: 70px;
                            }
                        }

                        > div {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-column-gap: 10px;
                            grid-row-gap: 10px;

                            > a {
                                text-decoration: none;
                                position: relative;
                                border-radius: 12px;
                                overflow: hidden;
                                transition: opacity 0.3s ease 0s;

                                > .catalog__list__item__background {
                                    position: absolute;
                                    z-index: 88;
                                    background: #7878787a;
                                    height: 100%;
                                    width: 100%;
                                    top: 0;
                                }

                                > :first-child {
                                    padding-top: 100%;
                                    position: relative;

                                    > div {
                                        border-radius: inherit;
                                        bottom: 0;
                                        left: 0;
                                        position: absolute;
                                        right: 0;
                                        top: 0;

                                        > img {
                                            height: 100%;
                                            background-size: contain;
                                            object-fit: contain;
                                            width: 100%;
                                            display: block;
                                        }
                                    }
                                }

                                > :last-child {
                                    position: absolute;
                                    z-index: 99;
                                    color: #fff;
                                    top: 0;
                                    text-transform: capitalize;
                                    height: 42px;
                                    overflow: hidden;
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 16px;
                                    padding: 12px 5px 12px 10px;
                                }

                                &:hover {
                                    opacity: 0.7;
                                    cursor: pointer;
                                    // transition: opacity 1s ease 0s;
                                }
                            }
                        }
                    }
                }

                > .skeleton__main {
                    > div {
                        margin-bottom: 10px;

                        > :first-child {
                            > .skeleton__main__path__first {
                                height: 20px;
                                width: 150px;
                                margin: 18.7px 0px;
                            }

                            margin-bottom: 10px;
                        }

                        > :last-child {
                            > div {
                                position: relative;
                                padding-top: 150%;

                                > div {
                                    border-radius: inherit;
                                    bottom: 0;
                                    left: 0;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    display: grid;
                                    grid-template-columns: repeat(2, 1fr);
                                    grid-column-gap: 10px;
                                    grid-row-gap: 10px;

                                    > div {
                                        border-radius: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 48rem) {
    .main {
        > .CContent {
            > div {
                > .catalog__list {
                    > .catalog__list__item {
                        > div {
                            > h3 {
                                font-size: 24px;
                                line-height: 36px;
                            }

                            > div {
                                grid-template-columns: repeat(3, 1fr);

                                > div {
                                    > :last-child {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }

                    > .skeleton__main {
                        > div {
                            > :last-child {
                                > div {
                                    padding-top: 75%;

                                    > div {
                                        grid-template-columns: repeat(3, 1fr);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 62rem) {
    .main {
        > .CContent {
            > div {
                > .catalog__list {
                    > .catalog__list__item {
                        > div {
                            > div {
                                grid-template-columns: repeat(4, 1fr);

                                > div {
                                    > :last-child {
                                        margin: 16px;
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }

                    > .skeleton__main {
                        > div {
                            > :first-child {
                                > .skeleton__main__path__first {
                                }
                            }

                            > :last-child {
                                > div {
                                    padding-top: 40%;

                                    > div {
                                        grid-template-columns: repeat(5, 1fr);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 80rem) {
    .main {
        width: 80rem;
        display: flex;

        > .CMenu {
            display: block;
            flex-shrink: 0;
            padding-right: 40px;
            width: 21%;
            margin-top: -6px;
        }

        > .CContent {
            > div {
                > .catalog__list {
                    > .catalog__list__item {
                        > div {
                            > div {
                                grid-template-columns: repeat(5, 1fr);
                            }
                        }
                    }
                }
            }
        }

        > :last-child {
            width: 79%;
        }
    }
}
