@import '@style/Mixin.scss';

.main {
    >div {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 auto;
        flex-flow: row wrap;
        margin-right: -0.5rem;
        margin-left: -0.5rem;

        >div {
            box-sizing: border-box;
            flex: 0 0 auto;
            padding-right: 0.5rem;
            padding-left: 0.5rem;

            >div {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: baseline;
                align-items: baseline;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 12px;

                >.dot_leaders {
                    flex: 1 1 0%;
                    margin: 0px 14px;
                    border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
                }

                .skeleton {
                    @include animationLoader;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0.89;
                    top: 0;
                }

                >:first-child,
                >:last-child {
                    position: relative;
                }

                >:first-child {
                    max-width: 56%;
                    color: rgb(120, 124, 135);
                }

                >:last-child {
                    width: 30%;
                    color: #161616;
                    -webkit-line-clamp: 3;
                    font-size: 14px;
                    overflow: hidden;
                    max-height: 54px;
                }
            }

        }
    }
}

@media only screen and (min-width: 0em) {
    .main {
        >div {
            >div {
                flex-basis: 100%;
                max-width: 100%;
                display: block;
            }
        }
    }
}

@media (min-width: 62rem) {
    .main {
        >div {
            >div {
                flex-basis: 50%;
                max-width: 50%;
                display: block;
            }
        }
    }
}