.main {
    margin-bottom: 20px;

    >h4 {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 16px;
        color: #787c87;
    }
}

.error {
    >h4 {
        color: #db3636;
    }

    >p {
        display: block;
        font-size: 14px;
        line-height: 16px;
        margin-top: 8px;
        color: rgb(219, 54, 54);
    }
}