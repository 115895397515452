.main {
    position: relative;

    >.fieldblock {
        padding: 0;
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        border-color: rgb(204, 204, 204);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        min-height: 38px;
        position: relative;
        transition: all 100ms ease 0s;
        box-sizing: border-box;
        outline: 0px !important;

        border: 1px solid rgb(242, 243, 245);
        border-radius: 25px;
        box-shadow: none;
        color: rgb(120, 124, 135);
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;

        >:first-child {
            padding: 0px 19px;
            margin: 0;
            color: rgb(120, 124, 135);
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            margin: 0px;
            max-width: 100%;
            position: static;
            transform: none;
        }

        >:last-child {
            color: #787c87;
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    >.selected {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        z-index: 99;
        background: #fff;
        padding: 12px 0px;
        border: 0px;
        border-radius: 8px;
        box-shadow: rgb(90 112 114 / 8%) 0px 4px 18px;
        cursor: pointer;
        margin-bottom: 8px;
        margin-top: 8px;

        >div {
            width: 100%;
            background-color: rgb(255, 255, 255);
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            outline: 0px;
            padding: 8px 12px;
            transition: background-color 0.1s ease 0s, color 0.1s ease 0s;

            &:hover {
                background-color: rgba(80, 185, 70, 0.15);
                color: rgb(80, 185, 70);
                transition: none 0s ease 0s;
            }
        }

        >.fieldSelectActive {
            background-color: rgba(80, 185, 70, 0.15);
            color: rgb(80, 185, 70);
        }
    }
}