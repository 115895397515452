.main {
    > div {
        width: 100%;
        padding: 24px;
        -webkit-box-align: center;
        align-items: center;
        padding: 15px 26px;
        background: rgb(238, 248, 237);
        border-radius: 8px;
        color: rgb(80, 185, 70);

        > div {
            > p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0px;
                margin-top: 18px;
            }

            > a {
                margin-top: 24px;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                cursor: pointer;
                background: rgb(80, 185, 70);
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                border-radius: 8px;
                border: none;
                height: 44px;
                padding: 0px 30px;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                width: auto;
                color: rgb(255, 255, 255);
                text-decoration: none;

                > span {
                    margin-left: 10px;
                }
            }
        }
    }
}

@media (min-width: 48rem) {
    .main {
        > div {
            > div {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                width: 100%;
            }
        }
    }
}

@media (min-width: 80rem) {
    .main {
        position: sticky;
        top: 89px;

        > div {
            max-width: 320px;
            margin-left: auto;
            font-size: 16px;
            line-height: 24px;

            > div {
                display: inline;

                > p {
                    max-width: 400px;
                }
            }
        }
    }
}
