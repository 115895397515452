@import "@style/GConst.scss";

.main {
    width: 100%;

    >.right {
        display: block;
        flex-shrink: 0;
        width: 100%;
        margin-bottom: 10px;
        aspect-ratio: 1/1;

        >:first-child {
            background-color: #efefef;
            overflow: hidden;
            border-radius: 5px;
            border: 1px solid $MAIN_COLOR;
            padding-top: 100%;
            position: relative;

            >div {
                border-radius: inherit;
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                >img {
                    width: 100%;
                    height: 100%;
                    background: #dddddd;
                    background-size: contain;
                    object-fit: contain;
                    width: 100%;
                    display: block;
                }
            }
        }

        >:last-child {
            margin-top: 15px;

            >div {
                color: rgb(120, 124, 135);
                font-size: 14px;
                line-height: 16px;

                >button {
                    width: 100%;
                }

                >span {
                    width: 100%;
                }

                >.skeleton__nav {
                    margin-bottom: 10px;
                    height: 16px;
                    width: 100%;
                }
            }


        }
    }

    .left {
        width: 100%;

        >.sort {
            color: #787c87;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 54px;
            // border-top: 1px dashed $MAIN_COLOR;
            // border-bottom: 1px dashed $MAIN_COLOR;
            margin-bottom: 15px;

            >:first-child {
                >:first-child {
                    color: rgb(120, 124, 135);
                    font-size: 14px;
                    line-height: 16px;

                    .skeleton__total {
                        height: 16px;
                        width: 80px;
                    }
                }
            }
        }
    }

    @media (min-width: 48rem) {
        display: flex;

        >.right {
            width: 21%;
            margin-bottom: 0px;
            padding-right: 40px;
        }

    }

    @media (min-width: 62rem) {}

    @media (min-width: 80rem) {}
}