@import "@style/GConst.scss";

.main {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    z-index: 40;
    width: 100%;
    position: relative;

    > :first-child {
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        width: 100%;

        >div {
            width: 100%;
            position: relative;

            >input {
                color: rgb(138, 138, 138);
                font-size: 16px;
                height: 40px;
                line-height: 21px;
                padding: 0px 74px 0px 12px;
                outline: none;
                text-overflow: ellipsis;
                width: 100%;
                border-radius: 8px;
                position: relative;
                display: inline-block;
                width: 100%;
                min-width: 0px;
                background-image: none;
                border: 1px solid transparent;
                transition: all 0.3s ease 0s;
                background-color: rgb(242, 243, 245);
            }

            >div {
                position: absolute;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                right: 16px;
                top: 16px;
                z-index: 1;

                >a {
                    position: absolute;
                    right: 30px;
                    top: -8px;
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    transition: opacity 0.2s ease 0s;
                    padding: 0;

                    >svg {
                        font-size: 24px;
                        color: rgb(182, 186, 194);
                    }
                }

                >div {
                    height: 16px;
                    background-color: rgb(221, 222, 225);
                    position: absolute;
                    right: 24px;
                    top: -4px;
                    width: 1px;
                }
            }
        }
    }

    >.photoImage {
        position: absolute;
        right: 40px;
        cursor: pointer;

        >input {
            position: absolute;
            visibility: hidden;
        }

        >svg {
            display: block;
            color: $MAIN_COLOR;
        }
    }

    >a {
        position: absolute;
        right: 0px;
        -webkit-box-align: center;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: inline-flex;
        -webkit-box-pack: center;
        justify-content: center;
        height: 100%;
        outline: none;
        padding: 0px 12px;
        transition: opacity 0.2s ease 0s;

        >svg {
            color: $MAIN_COLOR;
            font-size: 16px;
        }
    }

    @media (min-width: 62rem) {
        margin-left: -15px;
        transition: width 0.15s ease 0s;

        > :first-child {
            >div {
                >input {
                    padding-left: 35px;
                }
            }
        }
    }
}