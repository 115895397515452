@import "@style/GConst.scss";

.main {
    >div {
        >div {
            > :first-child {
                display: flex;
                align-items: center;

                > :first-child,
                > :last-child {
                    cursor: pointer;
                }

                > :first-child {
                    display: block;
                    -webkit-box-flex: 1;
                    flex-grow: 1;
                    font-weight: 600;
                    overflow: hidden;
                    padding: 5px 0px;
                    text-decoration: none;
                    color: rgb(0, 0, 0);
                    transition: opacity 0.3s ease 0s;
                    text-transform: capitalize;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                > :last-child {
                    display: flex;
                }
            }

            > :last-child {
                padding-left: 11px;
                padding-top: 2px;
                max-height: 0px;
                overflow: hidden;
                -webkit-transition: max-height 0.3s ease-out;
                -moz-transition: max-height 0.3s ease-out;
                -o-transition: max-height 0.3s ease-out;
                -ms-transition: max-height 0.3s ease-out;
                transition: max-height 0.3s ease-out;

                >a {
                    display: block;
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 4px;
                    padding: 5px;
                    text-decoration: none;
                    color: rgb(0, 0, 0);
                    transition: opacity 0.2s ease 0s;
                }
            }

            >.active__link {
                >span {
                    color: $MAIN_COLOR !important;
                }
            }
        }
    }

    >.active {
        >div {
            > :last-child {
                max-height: 500px;
                -webkit-transition: max-height .5s ease-out;
                -moz-transition: max-height .5s ease-out;
                -o-transition: max-height .5s ease-out;
                -ms-transition: max-height .5s ease-out;
                transition: max-height .5s ease-out;
            }
        }
    }
}

.skeleton {
    height: 30px;
    margin-bottom: 10px;
}