@import "@style/index.scss";

.main {
    width: 100%;

    >.banner {
        display: flex;
        flex-direction: column;

        >:first-child {
            position: relative;
            margin-bottom: 20px;
        }

        >:last-child {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            >a {
                display: flex;
                text-decoration: none;
                color: #4d4d51;
                border-radius: 16px;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0px;
                }

                >div {
                    width: 50%;
                }

                >:first-child {
                    >div {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        padding: 20px;
                        justify-content: space-around;

                        >:first-child {
                            font-weight: 600;
                            line-height: initial;
                        }
                    }
                }

                >:last-child {
                    padding: 5px 13px;


                    >div {
                        >div {
                            padding-top: 100%;
                            position: relative;

                            >div {
                                border-radius: inherit;
                                bottom: 0;
                                left: 0;
                                position: absolute;
                                right: 0;
                                top: 0;

                                >img {
                                    width: 100%;
                                    height: 100%;
                                    background-size: contain;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 48rem) {
        >.banner {

            >:first-child {
                margin-bottom: 20px;
            }

            >:last-child {
                >a {
                    >:first-child {
                        >div {
                            padding: 21px;

                            >:first-child {
                                font-size: 30px;
                            }

                            >:last-child {
                                font-size: 15px;
                            }
                        }
                    }

                    >:last-child {
                        padding: 21px;
                    }
                }
            }
        }
    }

    @media (min-width: 62rem) {
        >.banner {
            // margin: 64px 0px 44px;
            flex-direction: row;

            >:first-child {
                width: 67%;
                margin-bottom: 0px;
            }

            >:last-child {
                width: 33%;
                flex-direction: column;
                margin-left: 15px;

                >a {
                    >:first-child {
                        >div {
                            padding: 20px;

                            >:first-child {
                                font-size: initial;
                            }

                            >:last-child {
                                font-size: 15px;
                            }
                        }
                    }

                    >:last-child {
                        padding: 5px 13px;
                    }

                    >:first-child {
                        >div {
                            >:first-child {
                                // line-height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 80rem) {
        >.banner {
            // margin: 64px 0px;

            >:first-child {
                width: 67%;
                margin-bottom: 0px;
            }

            >:last-child {
                margin-left: 20px;

                >a {
                    >:first-child {
                        >div {
                            >:first-child {
                                // line-height: initial;
                            }
                        }
                    }
                }
            }
        }
    }
}