@import "@style/GConst.scss";

.main {
    margin-bottom: 20px;

    >label {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 16px;
        color: rgb(120, 124, 135);
    }

    >input {
        border-radius: 8px;
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0px;
        padding: 18px 16px;
        font-size: 16px;
        line-height: 19px;
        background-image: none;
        border: 1px solid transparent;
        transition: all 0.3s ease 0s;
        background-color: rgb(247, 247, 247);

        &:focus {
            outline: none;
            border: 1px solid $MAIN_COLOR;
        }
    }

    >p {
        display: block;
        font-size: 14px;
        line-height: 16px;
        margin-top: 8px;
        color: rgb(219, 54, 54);
    }
}

.error {
    >label {
        color: rgb(219, 54, 54);
    }

    >input {
        color: rgb(219, 54, 54);
        border: 1px solid rgb(219, 54, 54) !important;
    }
}