.main {
    width: 100%;

    .content__header__counter {
        button {
            width: 100%;
        }

        >div {
            >div {
                margin-bottom: 10px;
            }
        }
    }

    >:last-child {
        >.button {
            text-align: center;

            >.disable {
                opacity: 0.5;
            }

            >button {
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                cursor: pointer;
                background: rgb(238, 248, 237);
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                border-radius: 8px;
                border: none;
                height: 44px;
                padding: 0px 30px;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                width: auto;
                color: rgb(80, 185, 70);

                &:hover {
                    color: rgb(255, 255, 255);
                    background-color: rgb(80, 185, 70);
                }
            }
        }
    }

    @media (min-width: 48rem) {}

    @media (min-width: 62rem) {}

    @media (min-width: 80rem) {
        display: flex;

        >:first-child {
            width: 21%;
            display: block;
            flex-shrink: 0;
            margin-top: -6px;
            padding-right: 40px;
        }

        >:last-child {
            width: 79%;
        }
    }
}