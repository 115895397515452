.main {
    position: relative;

    >div {
        position: absolute;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        z-index: 1;
        color: rgb(120, 124, 135);
        pointer-events: none;
    }

    >.form {
        font-weight: 500;
        top: 11px;
        left: 8px;
    }

    >.to {
        top: 14px;
        right: 8px;
    }

    >.handler {
        border-radius: 8px;
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0px;
        padding: 18px 48px;
        font-size: 16px;
        line-height: 19px;
        background-image: none;
        border: 1px solid transparent;
        transition: all 0.3s ease 0s;
        background-color: rgb(247, 247, 247);
        color: rgb(120, 124, 135);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 12px 24px 12px 32px;
        text-align: center;

        &:focus {
            outline: none;
            border: 1px solid rgb(80, 185, 70);
        }
    }

    >.error {
        border: 1px solid #db3636;
    }
}