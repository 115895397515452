.main {
    flex: 1 1 0%;
    @media (min-width: 80rem) {
        > div {
            margin-top: 64px;
            padding-bottom: 98px;
        }
    }

    @media (min-width: 48rem) {
        > div {
            margin-top: 44px;
            padding-bottom: 80px;
        }
    }
}
