.main {
    >.filter {
        ul {
            padding: 0;

            li {
                list-style: none;
            }
        }

        >ul {
            >li {
                display: flex;
                align-items: center;
                justify-content: space-between;

                >:first-child {
                    display: block;
                    -webkit-box-flex: 1;
                    flex-grow: 1;
                    font-weight: 600;
                    overflow: hidden;
                    padding: 5px 0px;
                    text-decoration: none;
                    color: rgb(0, 0, 0);
                    transition: opacity 0.3s ease 0s;
                    text-transform: capitalize;
                }

                >:last-child {
                    display: flex;
                }
            }

            >ul {
                position: relative;
                overflow: auto;
                height: auto;
                min-height: auto;
                max-height: 340px;
                -webkit-scrollbar: 6px;
                margin-right: 8px;

                &::-webkit-scrollbar-thumb {
                    background-color: rgb(80, 185, 70);
                    border-radius: 14px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px rgb(242, 243, 245);
                    border-radius: 14px;
                    width: 8px;
                }

                &::-webkit-scrollbar {
                    width: 4px;
                }


                >li {
                    padding: 4px 15px 4px 4px;

                    >label {
                        display: flex;
                        cursor: pointer;
                        align-items: center;

                        >:first-child {
                            position: relative;
                            width: 16px;
                            height: 16px;
                            height: 16px;
                            border-radius: 2px;

                            input[type=checkbox] {
                                position: absolute;
                                cursor: pointer;
                                height: 16px;
                                width: 16px;
                                margin: 0px;
                            }

                            input[type=checkbox]:before {
                                content: "";
                                display: inline-block;
                                width: 17px;
                                height: 17px;
                                background-color: #eee;
                            }

                            >input[type=checkbox]:checked:before {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpolyline style='stroke:white;fill:none;stroke-width:2' points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
                                background-color: rgb(80, 185, 70);
                            }
                        }

                        >:last-child {
                            margin-left: 14px;
                            text-transform: capitalize;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}
