@import "@style/Mixin.scss";

.review {
    >div {
        margin-bottom: 20px;

        >div {
            padding: 24px;
            background: rgb(247, 247, 247);
            opacity: 0.9;
            border-radius: 12px;

            > :first-child {
                display: flex;
                -webkit-box-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                align-items: center;

                >div {
                    display: flex;

                    >p {
                        padding-right: 16px;
                        margin: 0px;
                        font-size: 14px;
                        line-height: 16px;
                        letter-spacing: -0.08px;
                        color: rgb(22, 22, 22);
                        font-weight: 600;
                        opacity: 0.5;
                    }

                    >div {
                        display: flex;

                        >img {
                            height: 16px;
                        }
                    }
                }

                >p {
                    margin: 0px 0px 4px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: rgb(22, 22, 22);
                }
            }

            >span {
                margin-top: 10px;
                margin-bottom: 0px;
                font-size: 14px;
                line-height: 26px;
                color: rgb(22, 22, 22);
                position: relative;
                font-weight: 600;
                display: inline-block;

                >.skeleton {
                    @include animationLoader;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0.89;
                    top: 0;
                }
            }
        }
    }
}

@media (min-width: 48rem) {
    .review {
        >div {
            >div {
                > :first-child {
                    flex-wrap: wrap;

                    >p {
                        font-size: 16px;
                        line-height: 26px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 80rem) {
    .review {
        >div {
            >div {
                > :first-child {
                    >p {
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}