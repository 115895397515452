@import "@style/GConst.scss";

.main {
    display: block;
    margin-bottom: 16px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgb(41 72 0 / 7%) 0px 6px 30px;
    text-decoration: none;

    .image {
        position: relative;

        > :first-child {
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:focus {
                outline: none !important;
            }

            > div {
                padding-top: 100%;
                position: relative;

                > div {
                    border-radius: inherit;
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;

                    > img {
                        width: 100%;
                        height: 100%;
                        background: #dddddd;
                        background-size: contain;
                        object-fit: contain;
                        width: 100%;
                        display: block;
                    }
                }
            }
        }

        > :last-child {
            position: absolute;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 8px;
            top: 0;

            > div {
                > .discount {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 25px;
                    padding: 0px 7px;
                    height: 18px;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTYgMzYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTQ3LjQyIDM2aC44OGEzLjUzIDMuNTMgMCAwMDMuMzYtMi41OGw0LjItMTQuMzhjLjE5LS42OC4xOS0xLjQgMC0yLjA4bC00LjItMTQuMzhBMy41MyAzLjUzIDAgMDA0OC4zIDBINy43YTMuNTMgMy41MyAwIDAwLTMuMzYgMi41OEwuMTQgMTYuOTZjLS4xOS42OC0uMTkgMS40IDAgMi4wOGw0LjIgMTQuMzhBMy41MyAzLjUzIDAgMDA3LjcgMzZoMzkuNzJ6IiBmaWxsPSIjRkFENjU2Ii8+Cjwvc3ZnPgo=);
                    color: rgb(0, 0, 0);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }

            > :last-child {
                display: flex;

                > svg {
                    color: #e4e4e4;
                }
            }
        }
    }

    .content {
        padding: 8px;

        > .meta {
            display: flex;
            justify-content: space-between;

            > :first-child {
                display: flex;

                > div {
                    display: flex;
                    align-items: center;

                    > span {
                        margin: 0;
                    }
                }

                > :first-child {
                    > svg {
                        color: #fad656;
                    }

                    > span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: rgb(33, 33, 33);
                    }
                }

                > :last-child {
                    margin-left: 8px;

                    > svg {
                        color: #e4e4e4;
                    }

                    > span {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        margin-left: 4px;
                        color: rgb(182, 186, 194);
                    }
                }
            }

            > :last-child {
                color: rgb(182, 186, 194);
                flex-shrink: 0;
                font-size: 12px;
                line-height: 20px;
            }
        }

        .title {
            // margin-top: 15px;
            position: relative;
            height: 28px;

            > a {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                font-size: 12px;
                font-weight: 600;
                line-height: 14px;
                overflow: hidden;
                height: 28px;
                color: rgb(33, 33, 33);
                text-overflow: ellipsis;
                text-decoration: none;

                -webkit-tap-highlight-color: transparent;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                &:focus {
                    outline: none !important;
                }

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        > .skeleton {
            > div {
                > div {
                    height: 12px;
                    margin-top: 2px;
                }
            }
        }

        > .control {
            flex-direction: column;
            -webkit-box-align: stretch;
            align-items: stretch;
            margin-top: 4px;
            display: flex;
            min-height: 48px;

            > .price {
                -webkit-box-flex: 1;
                flex-grow: 1;
                margin-bottom: 8px;

                > div {
                    display: flex;
                    flex-direction: column-reverse;

                    > :first-child {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgb(0, 0, 0);
                    }

                    > :last-child {
                    }
                }

                > .PDiscount {
                    > :first-child {
                        color: rgb(219, 54, 54);
                    }

                    > :last-child {
                        color: rgb(182, 186, 194);
                        text-decoration: line-through;
                        display: none;
                    }
                }
            }

            > .card {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                cursor: pointer;
                background: $MAIN_COLOR;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                border: none;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                width: auto;
                color: rgb(255, 255, 255);
                border-radius: 4px;
                padding: 4px;
                height: auto;
            }
        }
    }
}

@media (min-width: 48rem) {
    .main {
        .image {
            > :last-child {
                padding: 16px;

                > div {
                    > .discount {
                        line-height: 25px;
                        font-size: 13px;
                        height: 24px;
                        font-weight: 600;
                        padding: 0px 10px;
                    }
                }
            }
        }

        .content {
            padding: 16px;

            > .meta {
                > :last-child {
                    margin-top: 0px;
                }
            }

            > .title {
                margin-top: 13px;
                height: 60px;

                > a {
                    -webkit-line-clamp: 3;
                    font-size: 14px;
                    line-height: 20px;
                    height: 60px;
                }
            }

            > .skeleton {
                > div {
                    > div {
                        height: 15px;
                        margin-top: 6px;
                    }
                }
            }

            > .control {
                flex-direction: row;
                margin-top: 6px;
                -webkit-box-align: center;
                align-items: center;

                > .price {
                    margin-bottom: 0px;

                    > div {
                        > :first-child {
                            font-size: 24px;
                            line-height: 24px;
                        }

                        > :last-child {
                        }
                    }

                    > .PDiscount {
                        > :first-child {
                            color: rgb(219, 54, 54);
                        }

                        > :last-child {
                            display: block;
                        }
                    }
                }

                > .card {
                    height: 44px;
                    width: 44px;
                    border-radius: 50%;
                }
            }
        }
    }
}

@media (min-width: 62rem) {
    // .masonryGridColumn>a {
    //     -webkit-transition: color 0.3s ease-in-out, -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    //     transition: color 0.3s ease-in-out, -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    //     transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), color 0.3s ease-in-out;
    //     transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), color 0.3s ease-in-out,
    //         -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    //     &:hover {
    //         -webkit-transform: scale(1.06);
    //         transform: scale(1.06);
    //     }
    // }
}

@media (min-width: 90rem) {
    .main {
        .content {
            > .meta {
                > :first-child {
                    > :first-child,
                    > :last-child {
                        > span {
                            font-weight: 600;
                        }
                    }
                }

                > :last-child {
                    margin-top: 0px;
                }
            }
        }
    }
}
