@import "@style/GConst.scss";

.main {
    display: flex;
    align-items: center;

    .banner {
        >a {
            text-decoration: none;
            box-sizing: border-box;
            display: -ms-flexbox;
            display: flex;
            position: relative;
            border-radius: 15px;

            >:first-child,
            >:last-child {
                padding: 15px;
                width: 50%;
                -ms-flex-direction: column;
                flex-direction: column;
            }

            >:first-child {
                display: flex;
                -ms-flex-pack: center;
                justify-content: center;

                >div {
                    >div {
                        -webkit-box-orient: vertical;
                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        position: relative;
                        text-overflow: ellipsis;
                        margin-bottom: -2px;
                        padding-bottom: 2px;
                        color: #4d4d51;
                    }

                    >:first-child {
                        max-height: 96px;
                        font-weight: 600;
                        line-height: initial;
                    }

                    >:last-child {
                        max-height: 56px;
                        margin-top: 16px;
                    }
                }
            }

            >:last-child {
                >div {
                    >div {
                        padding-top: 100%;
                        position: relative;

                        >div {
                            border-radius: inherit;
                            bottom: 0;
                            left: 0;
                            position: absolute;
                            right: 0;
                            top: 0;

                            >img {
                                width: 100%;
                                height: 100%;
                                background-size: contain;
                                -o-object-fit: contain;
                                object-fit: contain;
                            }
                        }
                    }
                }

            }
        }
    }

    .swiperPrev,
    .swiperNext {
        width: 34px;
        height: 34px;
        position: absolute;
        background: rgb(255, 255, 255) none repeat scroll 0% 0%;
        z-index: 3;
        color: rgb(0, 0, 0);
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        box-shadow: rgba(41, 72, 0, 0.1) 0px 6px 45px;
        color: rgb(120, 124, 135);
    }

    .swiperPrev {
        left: -12px;

        &:after {
            font-size: 12px;
        }
    }

    .swiperNext {
        right: -12px;

        &:after {
            font-size: 12px;
        }
    }

    @media (min-width: 48rem) {
        .banner {
            >a {

                >:first-child,
                >:last-child {
                    padding: 21px 37px;
                }

                >:first-child {
                    >div {
                        >:first-child {
                            font-size: 30px;
                            line-height: 33px;
                        }

                        >:last-child {
                            font-size: 23px;
                        }
                    }
                }
            }
        }

        .swiperPrev,
        .swiperNext {
            width: 44px;
            height: 44px;
        }

        .swiperPrev {
            left: -22px
        }

        .swiperNext {
            right: -22px;
        }
    }

    @media (min-width: 62rem) {
        .banner {
            >a {
                >:first-child {
                    >div {
                        >:first-child {
                            font-size: 24px;
                            line-height: 26px;
                        }

                        >:last-child {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 80rem) {
        .banner {
            >a {

                >:first-child,
                >:last-child {
                    padding: 37px 56px;
                }

                >:first-child {
                    >div {
                        >:first-child {
                            font-size: 40px;
                            line-height: 48px;
                        }

                        >:last-child {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}