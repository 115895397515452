@import "@style/GConst.scss";

/**
 * YUI 3.5.0 - reset.css (http://developer.yahoo.com/yui/3/cssreset/)
 * https://cssdeck.com/blog/
 * Copyright 2012 Yahoo! Inc. All rights reserved.
 * http://yuilibrary.com/license/
 */
/*
	TODO will need to remove settings on HTML since we can't namespace it.
	TODO with the prefix, should I group by selector or property for weight savings?
*/
html {
  color: #000;
  background: #fff;
}

a,
div {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}

/*
	TODO remove settings on BODY since we can't namespace it.
*/
/*
	TODO test putting a class on HEAD.
		- Fails on FF.
*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

/*
	TODO think about hanlding inheritence differently, maybe letting IE6 fail a bit...
*/
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

/* to preserve line-height and selector appearance */
sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input,
textarea,
select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssreset {
  display: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

code,
kbd,
samp,
pre,
tt,
var,
textarea,
input,
select,
isindex,
listing,
xmp,
plaintext {
  font: inherit;
  font-size: 1em;
  white-space: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  color: rgb(33, 33, 33);
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

a {
  color: $MAIN_COLOR;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease 0s;
}

.container {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 1rem;
}

$maxWidth: 1280;

@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 320;
  font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
}

@media (min-width: 48rem) {
  .container {
    width: 48rem;
    padding: 0px 2rem;
  }
}

@media (min-width: 62rem) {
  .container {
    width: 62rem;
  }
}

@media (min-width: 80rem) {
  .container {
    width: 80rem;
  }
}