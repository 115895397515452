.main {
}

.masonryGrid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;

    > :first-child {
        padding-left: 0px;
    }
}

.masonryGridColumn {
    padding-left: 8px;
    background-clip: padding-box;
}

@media (min-width: 48rem) {
    .masonryGridColumn {
        padding-left: 16px;
    }
}

@media (min-width: 62rem) {
    .masonryGridColumn > div {
        -webkit-transition: color 0.3s ease-in-out, -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: color 0.3s ease-in-out, -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), color 0.3s ease-in-out;
        transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), color 0.3s ease-in-out,
            -webkit-transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &:hover {
            -webkit-transform: scale(1.06);
            transform: scale(1.06);
        }
    }
}
