.main {
    >:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    >:last-child {
        >.orderCustomer {
            >div {
                position: relative;
            }
        }
    }
}