.skeleton {
    .skeleton__slid {
        height: 160px;
        width: 160px;
    }

    >:first-child {
        display: flex;
        justify-content: center;
        height: 200px;
        align-items: center;
    }

    >:last-child {
        display: none;
    }
}

.main {
    >.product__gallery {
        margin-bottom: 24px;
        position: relative;
        min-height: 200px;

        >div {
            >.swiper_top_gallery_block {
                -webkit-box-align: center;
                align-items: center;
                display: flex;
                -webkit-box-pack: justify;
                justify-content: space-between;
                width: 100%;

                >div {
                    >.paginationCustom {
                        text-align: center;
                        display: block;
                    }

                    >div {
                        >div {
                            user-select: none;
                            -webkit-tap-highlight-color: transparent;
                            -webkit-box-align: center;
                            align-items: center;
                            display: flex;
                            -webkit-box-pack: center;
                            justify-content: center;
                            margin-right: 0px !important;

                            >a {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                >svg {
                                    position: absolute;
                                    color: #fff;
                                    background: #00000085;
                                    border-radius: 50%;
                                }
                            }

                        }
                    }

                    img,
                    video {
                        object-fit: cover;
                        height: 160px;
                        width: 160px;
                        cursor: grab;
                        background: #f2f3f5;
                        border-radius: 4px;
                    }
                }
            }

            >.swiper_gallery_block {
                margin-top: 32px;
                display: none;

                >div {
                    display: none;
                    height: 84px;

                    >div {
                        >div {
                            width: 84px;
                            cursor: pointer;
                            text-align: center;
                        }
                    }

                    img {
                        border-radius: 4px;
                        height: 100%;
                        object-fit: contain;
                        background: #f2f3f5;
                        max-width: 100px;
                    }
                }
            }
        }
    }
}

@media (min-width: 48rem) {
    .skeleton {
        display: flex;

        .skeleton__slid {
            height: 400px;
            width: 400px;
        }

        .skeleton__items {
            height: 84px;
            width: 84px;
        }

        >:first-child {
            display: flex;
            justify-content: center;
            height: 480px;
            align-items: center;
        }

        >:last-child {
            display: flex;
            justify-content: center;

            >div {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }

    .main {
        >.product__gallery {
            min-height: 400px;

            >div {
                >.swiper_top_gallery_block {
                    >div {
                        >.paginationCustom {
                            text-align: center;
                            display: none;
                        }

                        img,
                        video {
                            object-fit: cover;
                            height: 400px;
                            width: 400px;
                        }
                    }
                }

                >.swiper_gallery_block {
                    display: block;

                    >div {
                        >div {
                            // justify-content: center;

                            >div {}
                        }

                        display: flex;
                    }
                }
            }
        }
    }
}

@media (min-width: 62rem) {
    .main {
        >.product__gallery {
            position: sticky;
            top: 70px;
            margin-bottom: 0px;

            >div {
                >.swiper_top_gallery_block {}

                >.swiper_gallery_block {
                    >div {
                        // max-width: 460px;
                        margin: 0px 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: 80rem) {
    .main {
        >.product__gallery {
            position: sticky;
            top: 70px;
            margin-bottom: 0px;

            >div {
                >.swiper_top_gallery_block {
                    >div {
                        height: 480px;
                    }
                }

                >.swiper_gallery_block {
                    margin-top: 0px;
                }
            }
        }
    }
}