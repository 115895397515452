@mixin animationLoader {
    position: relative;
    background-color: #E7EDF1;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.5), transparent);
        animation: loading 1.5s infinite;
    }

    @keyframes loading {
        100% {
            transform: translateX(100%);
        }
    }
}